import request from '@/service/request'
import { getXMenuType } from '@/utils/constant'

export function list(params) {
  return request({
    url: '/api/product/size_spec/',
    method: 'get',
    params
  })
}

export function getPsdBySize(params) {
  return request({
    url: '/api/product/size_spec/get_psd_file/',
    method: 'get',
    params
  })
}

export function getBasePsdByProtoId(params) {
  return request({
    url: '/api/prim_prod/size_base/get_psd_file/',
    method: 'get',
    params
  })
}

export function add(data) {
  return request({
    url: '/api/product/size_spec/',
    method: 'post',
    data
  })
}

export function read(data) {
  return request({
    url: `/api/product/size_spec/${data}/`,
    method: 'get',
    data
  })
}

export function edit(id, data) {
  return request({
    url: '/api/product/size_spec/' + id + '/',
    method: 'patch',
    data
  })
}

// 尺码排序
export function sortEdit(data) {
  return request({
    url: '/api/product/size_spec/set_size_sort',
    method: 'post',
    data
  })
}

export function del(id) {
  return request({
    url: '/api/product/size_spec/' + id + '/',
    method: 'delete'
  })
}

// 更新参考尺码
export function updateReferSize(data) {
  return request({
    url: '/externaladmin/productService/referSize/updateReferSize',
    method: 'post',
    data
  })
}

// 批量设置参考尺码
export function batchEditReferSize(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/productService/referSize/batchEditReferSize',
    method: 'post',
    data
  })
}
// 批量关联参考尺码
export function batchRelationReferSize(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/productService/referSize/batchRelationReferSize',
    method: 'post',
    data
  })
}

export function setKlProdSite(list, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/productService/quickDesignCombination/create',
    method: 'post',
    data: { list }
  })
}

export function getKlProdSite(id, menuType = 'other') {
  return request({
    url: '/externaladmin/productService/quickDesignCombination/getByReferSizeId',
    method: 'post',
    data: { id }
  })
}

export function updateKlProdSiteName(data) {
  return request({
    url: '/api/prod_design/combinations/bulk_edit',
    method: 'post',
    data
  })
}

export function setKlProdDesignPic(list, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/productService/quickDesignCombination/batchDisplaySet',
    method: 'post',
    data: { list }
  })
}

//添加定制器描述
export function setDesignSuggestions(data, menuType = 'btn') {
  return request({
    url: '/externaladmin/productService/referSizePsd/setDesignSuggestions',
    method: 'post',
    data
  })
}

export function updateKlProdDesignPic(data) {
  return request({
    url: '/api/prod_design/design_pic/bulk_edit',
    method: 'post',
    data
  })
}

//原型尺码表-获取
export function getSizePropInfo(id) {
  return request({
    url: `/api/prim_prod/introduce_attr/info_detail?prim_prod=${id}`,
    method: 'get'
  })
}

//原型尺码表-添加
export function addSizePropInfo(data) {
  return request({
    url: '/api/prim_prod/introduce_attr/info_create',
    method: 'post',
    data
  })
}

//原型尺码表-编辑
export function editSizePropInfo(data) {
  return request({
    url: '/api/prim_prod/introduce_attr/info_edit',
    method: 'post',
    data
  })
}
